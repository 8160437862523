<script setup>

// CartPage
// Shows the current contents of the cart.

// Components ----
import CartItemList from './components/CartItemList';
import ConferenceOrderTile from '@/site/components/ConferenceOrderTile'
import CartBodyPanel from '@/site/areas/cart/components/CartBodyPanel';

// Imports ----
import { useRouter } from 'vue-router';
import { store } from '@/Store';

const router = useRouter();

// State ----

// Handlers ----
function onCheckout() {
    router.push({ name: 'Checkout' });
}
</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <div v-if="store.cart.current.items.length > 0" class="float-end">
                <router-link class="btn btn-outline-color-1 me-2" to="/products">CONTINUE SHOPPING</router-link>
                <button class="btn btn-color-1" @click="onCheckout"
                    :disabled="!store.cart.current.canPlaceOrder">CHECKOUT</button>
            </div>
            <h1 class="cart-page-header mb-4">Shopping Cart</h1>

            <div class="row">
                <div class="col-9">
                    <CartItemList class="mt-5" :editable="true">
                        <template #empty>
                            <div class="text-center">
                                Your cart is currently empty.<br /><br />
                                <router-link class="btn btn-outline-color-1 me-2" to="/products">CONTINUE
                                    SHOPPING</router-link>
                            </div>
                        </template>
                    </CartItemList>
                </div>
                <div class="col-3">
                    <div class="summary mt-5">
                        <CartBodyPanel class="cart-body-panel">
                            <template #title>
                                <h2>Order Summary</h2>
                            </template>

                            <ConferenceOrderTile title="Please confirm, if this is a Conference Order" />
                        </CartBodyPanel>
                    </div>
                </div>
            </div>
        </div>
    </PageTemplate>
</template>

<style lang="scss"></style>