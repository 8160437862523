<script setup>

    // OrderDetailPage
    // Shows the user details about a placed order

    // Components ----
    import CartBodyPanel from '../cart/components/CartBodyPanel';
    import OrderShipTo from '../cart/components/OrderShipTo';
    import OrderItemList from '../cart/components/OrderItemList';
    import OrderShipToMultiple from '../cart/components/OrderShipToMultiple';

    // Imports ----
    import { computed, reactive, onMounted, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { store } from '@/Store';
    import { GeneralTableWrapper, Util } from '@dd-nucleus/nucleus-vue';

    const route = useRoute();
    const router = useRouter();

    // State ----
    const order = reactive({ isLoaded: false, detail: {} });
    const trackingInfo = reactive([]);
    let isShow = reactive(true);
    let buttonText = ref('Show All');

    // Computed ----
    const shipMethod = computed(() => {
        return order.detail.shipMethod;
    })

    const isExpeditedShipping = computed(() => {
        return shipMethod.value?.isRush;
    });

    const isShowList = computed(() => {
        order.detail.shipments.forEach(function (shipment, i) {
            if (i < 10) {
                trackingInfo.push({ trackingUrl: shipment.trackingUrl, trackingNumber: shipment.trackingNumber });
            }
        });
        if (order.detail.shipments.length > 10 && !(order.detail.shipments.length > 1 && order.detail.shipments.length <= 10)) {
            return true;
        }
        else if ((!(order.detail.shipments.length > 10) && (order.detail.shipments.length > 1 && order.detail.shipments.length <= 10))) {
            return true;
        }
        else
            return false;
    })

    const isShowButton = computed(() => {
        if (order.detail.shipments.length > 10 && !(order.detail.shipments.length > 1 && order.detail.shipments.length <= 10)) {
            return true;
        }
        else {
            return false;
        }
    })

    const isShowSingleTracking = computed(() => {
        if (order.detail.shipments.length == 1) {
            return true;
        }
        else {
            return false;
        }
    })


    const shipListCount = computed(() => {
        if (order.detail.isMultiShip) {
            return order.detail.orders.length
        }
        else
            return '';
    });

    const activityColumns = [
        { field: 'typeName', title: 'Type Name', inputType: 'text' },
        { field: 'field', title: 'Field', inputType: 'text' },
        { field: 'oldValue', title: 'Old Value', inputType: 'text' },
        { field: 'newValue', title: 'New Value', inputType: 'text' },
        { field: 'updateBy', title: 'Updated By', inputType: 'text' },
        { field: 'eventDate', title: 'Event Date', inputType: 'text' },
    ]


    const activityResults = reactive([]);

    async function getActiveOrders() {
        const payload = {
            filterValues: [
                {
                    filterId: "auditType",
                    values: [
                        "Order", order.detail.bulkOrderId != null ? order.detail.orders[0].id : order.detail.id
                    ]
                }
            ],
            paging: {
                pageNumber: 1,
                perPage: 100,
                includeTotalCount: true,
                includePageCount: true
            },
            sortId: "auditEvent-eventDate-desc"
        }
        let count = 1;
        const res = await store.individualOrder.getActiveOrders(payload);
        activityResults.splice(0, activityResults.length);
        if (res.succeeded) {
            for (let result of res?.results) {
                for (let auditEvent of result?.auditEventDetails) {
                    activityResults.push({
                        id: count,
                        typeName: result.typeName,
                        field: auditEvent.field,
                        oldValue: auditEvent.oldValue,
                        newValue: auditEvent.newValue,
                        updateBy: result.updateBy,
                        eventDate: Util.date(result.eventDate, true)
                    });
                    count++;
                }
            }
        } else {
            store.toast.error('Failed to get activity results');
        }
    }

    // Handlers ----
    onMounted(async () => {
        // If an ID was provided, get that order. If none was provided, use cart's order property, which is set when the
        // order is placed.
        if (Util.isEmpty(route.params.id))
            Object.assign(order.detail, store.cart.order);
        else {
            Object.assign(order.detail, await store.orders.getOrder(route.params.id));
            console.log('ORDER DETAIL', order.detail);
        }

        update();

        await getActiveOrders();
    });

    // Called once we know we have an order to work with
    function update() {
        order.isLoaded = true;
    }

    // Called by the back arrow link button, returns to the previous route
    function back() {
        router.go(-1);

    }

    //Called when clicked on showAll buttton
    function toggleButtonText() {
        isShow = !isShow;
        buttonText.value = !isShow ? 'Show Less' : 'Show All';
        let itemCount = 0;
        //Show only 10 records
        trackingInfo.length = 0;
        itemCount = isShow ? 10 : order.detail.shipments.length;

        order.detail.shipments.forEach(function (shipment, i) {
            if (i < itemCount) {
                trackingInfo.push({ trackingUrl: shipment.trackingUrl, trackingNumber: shipment.trackingNumber });
            }
        });
    }


</script>

<template>
    <PageTemplate>
        <div class="container root-page review-page" v-if="order.isLoaded">

            <div class="d-flex justify-content-between">
                <div>
                    <h1 v-if="order.detail.isMultiShip" class="cart-page-header mb-3">
                        Order #{{
                        order.detail.orders[0].bulkOrderNumber
                        }} <span class="text-muted">/ Details</span>
                    </h1>
                    <h1 class="cart-page-header mb-3" v-else>
                        Order #{{ order.detail.orderNumber }} <span class="text-muted">/ Details</span>
                    </h1>
                </div>
                <div>
                    <button class="btn btn-outline-color-1 me-2" @click="back">BACK TO PREVIOUS</button>
                    <span class="d-block d-sm-none mt-2"></span>
                    <router-link class="btn btn-outline-color-1 me-2" to="/">BACK TO HOME</router-link>
                </div>
            </div>

            <div class="row">
                <div class="col">

                    <!-- If Multi ship-->
                    <CartBodyPanel class="cart-body-panel" v-if="order.detail.isMultiShip">

                        <template #title>
                            <h2>Shipping Details <span class="fs-3">({{ shipListCount }} recipients)</span></h2>
                        </template>

                        <OrderShipToMultiple :order="order.detail.orders" />

                    </CartBodyPanel>

                    <CartBodyPanel class="cart-body-panel" v-else>
                        <template #title>
                            <h2>Ship To</h2>
                        </template>

                        <div class="d-flex">
                            <div class="flex-fill">
                                <OrderShipTo :order="order.detail" />
                            </div>
                            <div>
                                <div v-if="order.detail.orderStatus === 'Canceled'" class="shipments text-center">
                                    <div class="shipment">
                                        <div class="summary">
                                            <i class="bi bi-x-circle-fill text-danger"></i> CANCELED
                                        </div>
                                    </div>
                                </div>
                                <div v-if="isShowSingleTracking" class="shipments text-center">
                                    <div class="shipment">
                                        <div class="summary">
                                            <i class="bi bi-check-circle-fill text-success"></i>
                                            SHIPPED ON {{
                                                Util.date(order.detail.shipments[0].shipDate)
                                            }}
                                        </div>
                                        <div v-if="Util.isNotEmpty(order.detail.shipments[0].trackingUrl)">
                                            <a :href="order.detail.shipments[0].trackingUrl" class="btn btn-color-1"
                                               target="_blank">
                                                TRACK
                                                YOUR ORDER
                                            </a><br />
                                            <div class="tracking">
                                                Your tracking number is<br /><a :href="order.detail.shipments[0].trackingUrl">
                                                    {{
                                                    order.detail.shipments[0].trackingNumber
                                                    }}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="isShowList" class="shipments text-center">
                                    <div class="n-order-tracking">Tracking</div>
                                    <div v-for="(shipments, index) in trackingInfo" :key="index">
                                        <div class="shipment">
                                            <div v-if="isNotEmpty(shipments.trackingUrl)">
                                                <a :href="shipments.trackingUrl" target="_blank">
                                                    {{
                                                    shipments.trackingNumber
                                                    }}
                                                </a><br />
                                            </div>
                                        </div>
                                    </div>
                                    <button v-if="isShowButton" class="btn btn-outline-color-1 me-2" id="btnToggle"
                                            @click="toggleButtonText">
                                        {{ buttonText }}
                                    </button>
                                </div>
                            </div>
                        </div>

                    </CartBodyPanel>

                    <CartBodyPanel class="cart-body-panel ship-method-panel" v-if="!order.detail.isMultiShip">
                        <template #title>
                            <h2>Ship Method</h2>
                        </template>

                        <div v-if="!isExpeditedShipping">
                            <ShipMethod :ship-method="shipMethod" />
                        </div>
                        <div v-else>{{ shipMethod.name }}</div>

                    </CartBodyPanel>

                    <CartBodyPanel class="cart-body-panel order-items-panel" v-if="!order.detail.isMultiShip">
                        <template #title>
                            <h2>Items</h2>
                        </template>

                        <OrderItemList :order="order.detail" />

                    </CartBodyPanel>

                    <CartBodyPanel class="cart-body-panel" v-if="activityResults.length>0">

                        <template #title>
                            <h2>Activity</h2>
                        </template>

                        <div class="activity-table">
                            <GeneralTableWrapper :columns="activityColumns" :results="activityResults" />
                        </div>

                    </CartBodyPanel>
                </div>
            </div>

        </div>
    </PageTemplate>
</template>

<style lang="scss">
    .root-page {
        min-height: 30rem;
    }

    .review-page {
        .order-message h2 {
            font-weight: $normal;
        }

        .order-number {
            font-size: 1.2rem;
            font-weight: $bold;
        }

        .ship-method .title {
            font-weight: bold;
        }

        .panel-title-action {
            margin-top: -0.75rem;
        }

        .bottom-panel {
        }

        .shipment {
            .summary {
                font-weight: $bold;
                color: $gray-50;
                margin-bottom: 0.5rem;
            }

            .tracking {
                font-size: 90%;
                margin-top: 0.5rem;
            }
        }

        .activity-table {
            position: relative;

            .no-results-found {
                top: 100px;
                left: 40%;
            }
        }
    }

    .n-order-tracking {
        color: black;
        font-weight: bold;
    }
</style>