<script setup>

// ReviewPage
// Allows the user to review their order before placing.

// Components ----
import CartBodyPanel from './components/CartBodyPanel';
import ShipTo from './components/ShipTo';
import CartItemList from './components/CartItemList';

// Imports ----
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { store } from '@/Store';

const router = useRouter();

// State ----
const isLoaded = ref(false);
const placingOrder = ref(false);
const isConferenceType = ref(store.cart.current.attributes?.allowLimitOverride === "true");

// Computed ----
const canPlaceOrder = computed(() => {
    return (store.cart.current.canPlaceOrder === true && placingOrder.value === false);
});

const shipMethod = computed(() => {
    return store.cart.current.shipMethod;
});

const isExpeditedShipping = computed(() => {
    return shipMethod.value?.isRush;
});

// Handlers ----
onMounted(() => {
    update();
});

store.cart.onCartLoaded(() => {
    isConferenceType.value = store.cart.current.attributes?.allowLimitOverride === "true";
    update();
});

store.cart.onCartUpdated(() => {
    isConferenceType.value = store.cart.current.attributes?.allowLimitOverride === "true";
    update();
});

// Called once we know we have a cart to work with
function update() {
    isLoaded.value = true;
}

function onChangeShipTo() {
    router.push('/checkout');
}

function onChangeShipMethod() {
    router.push('/checkout');
}

function onChangeItems() {
    router.push('/cart');
}

async function onPlaceOrder() {
    placingOrder.value = true;

    const response = await store.cart.placeOrder('Cart');

    console.warn("response: ", response)

    //TODO: Commenting below line. need to understand the use of below line.
    //store.cart.ShipToMode.ShipToContacts

    if (response.succeeded)

        if (response.model.isMultiShip) {

            router.push('/order-confirmed/' + response.model.bulkOrderId);

        } else {

            router.push('/order-confirmed/' + response.model.id);

        }

    else {
        // TODO: How to get messages back?
        store.cart.current.canPlaceOrder = true;
        placingOrder.value = false;
    }
}
</script>

<template>
    <PageTemplate>
        <div class="container root-page review-page">

            <div class="float-end">
                <router-link class="btn btn-outline-color-1 me-2" to="/checkout">BACK</router-link>
                <button class="btn btn-color-1" :disabled="!canPlaceOrder" @click="onPlaceOrder">PLACE ORDER</button>
            </div>

            <h1 class="cart-page-header mb-3">Checkout <span class="text-muted">/ Review Order</span></h1>

            <div v-if="store.cart.current.requiresApproval" class="alert alert-warning alert-icon">
                <i class="bi bi-exclamation-triangle-fill"></i> &nbsp;When placed, this order will require approval
                before it can be fulfilled.
            </div>

            <MessageList :messages="store.cart.current.messages" :add-box="false" class="alert alert-danger" />

            <div class="row">
                <div class="col">
                    <CartBodyPanel class="cart-body-panel">
                        <template #title>
                            <div class="float-end">
                                <ActionButton class="panel-title-action" icon="bi bi-arrow-left-right" label="Change"
                                    @click="onChangeShipTo" />
                            </div>
                            <h2>Ship To</h2>
                        </template>

                        <ShipTo :editable="false" />

                    </CartBodyPanel>

                    <CartBodyPanel class="cart-body-panel ship-method-panel">
                        <template #title>
                            <div class="float-end">
                                <ActionButton class="panel-title-action" icon="bi bi-arrow-left-right" label="Change"
                                    @click="onChangeShipMethod" />
                            </div>
                            <h2>Ship Method</h2>
                        </template>

                        <div v-if="isLoaded && !isExpeditedShipping">
                            <ShipMethod />
                        </div>
                        <div v-else>{{ shipMethod?.name }}</div>

                    </CartBodyPanel>

                    <CartBodyPanel v-if="isConferenceType" class="cart-body-panel ship-method-panel">
                        <template #title>
                            <h2>Order Type</h2>
                        </template>

                        <div>
                            <p style="font-size: 16px">Conference Order</p>
                        </div>

                    </CartBodyPanel>

                    <CartBodyPanel class="cart-body-panel order-items-panel">
                        <template #title>
                            <div class="float-end">
                                <ActionButton class="panel-title-action" icon="bi bi-arrow-left-right" label="Change"
                                    @click="onChangeItems" />
                            </div>
                            <h2>Items</h2>
                        </template>

                        <CartItemList :editable="false" />

                    </CartBodyPanel>

                    <div v-if="isLoaded" class="bottom-panel">
                        <div class="mb-2 text-sm">Order confirmation and shipping information will be sent to <strong>{{
                                store.cart.current.user.email }}</strong>.</div>
                        <button class="btn btn-color-1" :disabled="!canPlaceOrder" @click="onPlaceOrder">PLACE
                            ORDER</button>
                    </div>
                </div>
            </div>

        </div>
    </PageTemplate>
</template>

<style lang="scss">
.root-page {
    min-height: 30rem;
}

.review-page {
    .ship-method .title {
        font-weight: bold;
    }

    .panel-title-action {
        margin-top: -0.75rem;
    }

    .bottom-panel {}
}
</style>