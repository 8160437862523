<script setup>

    // CheckoutPage
    // Allows the user to view and change the shipping information for the cart.

    // Components ----
    import CartBodyPanel from './components/CartBodyPanel';
    import ShipTo from './components/ShipTo';
    import { computed, ref } from 'vue';
    import { useRouter } from 'vue-router';

    // Imports ----
    import { store } from '@/Store';

    const router = useRouter();
    const reasonforexpedited = ref('');
    const isExpeditedReasonRequired = computed(() => {
        return store.cart.current.shipMethod?.isRush;
    });

    const disableContinue = computed(() => {
        if (reasonforexpedited.value.trim().length < 1 && isExpeditedReasonRequired.value === true)
            return true;
        else return false;
    });

    async function SaveReasonAndRoute() {
        SaveReasonforExpedited();
        router.push({ name: 'Review' });
    }

    async function SaveReasonforExpedited() {
    if (reasonforexpedited.value.length > 0 && isExpeditedReasonRequired.value === true) {
        const reasonNote = {
            holdReasonNote: reasonforexpedited.value.trim(),
            holdReasons: "Expedited Shipping"
        }
        await store.cart.addHoldReasonNote(store.cart.current.id, reasonNote);
    }
}

</script>

<template>
    <PageTemplate>
        <div class="container root-page checkout-page">

            <div class="float-end">
                <PageButton class="me-2" :outline="true" url="/cart">Back to Cart</PageButton>
                <PageButton v-if="!store.cart.isMultiShip()" class="me-2" @click="SaveReasonAndRoute" :disabled="disableContinue">Continue</PageButton>
            </div>

            <h1 class="cart-page-header mb-3">Checkout <span class="text-muted">/ Shipping</span></h1>

            <div class="row">
                <div class="col-12 col-md-9">
                    <CartBodyPanel class="cart-body-panel">
                        <template #title>
                            <h2>Ship To</h2>
                        </template>

                        <ShipTo :editable="true" :editing="true" :disableContinue="disableContinue" :reasonforexpedited="reasonforexpedited" :isExpeditedReasonRequired="isExpeditedReasonRequired" />

                    </CartBodyPanel>
                </div>

                <div class="col-12 col-md-3">
                    <CartBodyPanel class="cart-body-panel ship-method-panel">
                        <template #title>
                            <h2>Ship Method</h2>
                        </template>

                        <ShipMethodSelector v-model="store.cart.current.shipMethodCode" />

                        <div v-if="isExpeditedReasonRequired" class="mb-5 mt-3">
                            <h5 style="color: black;">Reason for Expedited Shipping</h5>
                            <textarea v-model="reasonforexpedited" maxlength="255" class="form-control" rows="5" placeholder="Please enter reason for Expedited Shipping"></textarea>
                        </div>
                    </CartBodyPanel>
                </div>
            </div>

        </div>
    </PageTemplate>
</template>

<style lang="scss">

    .root-page {
        min-height: 30rem;
    }

    .checkout-page {
        .n-body-panel {
            .body {
                padding: 0.75rem 0rem;
            }
        }

        .ship-method-panel {
            .body {
                padding-left: 1.5rem;
            }
        }
    }

</style>